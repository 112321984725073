<template>
  <v-card
    color="orange lighten-2"
    dark width="600px"
  >
    <v-card-title class="text-h5 orange lighten-3">
      Recommended Activities
    </v-card-title>
    <v-card-text>
      <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        color="white"
        hide-no-data
        hide-selected
        item-text="SearchText"
        item-value="API"
        label="Activities"
        placeholder="Start typing to Search"
        prepend-icon="mdi-database-search"
        return-object
        @change="addItem(model)"
      ></v-autocomplete>
    </v-card-text>
    <v-divider></v-divider>
    <!-- <v-expand-transition>
      <v-list
        v-if="model"
        class="red lighten-3"
      >
        <v-list-item
          v-for="(field, i) in fields"
          :key="i"
        >
          <v-list-item-content>
            <v-list-item-title v-text="field.value"></v-list-item-title>
            <v-list-item-subtitle v-text="field.key"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expand-transition> -->
    <v-list class="white">
        <v-list-item v-for="(obj, i) in recommendedSpots" :key="i" light>
            <v-list-item-avatar>
                <v-img :src="$store.state.config.mediaHost + obj._id + '/thumbnail/' + obj.thumbnail.name"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ obj.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ obj.category.name }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-icon @click="removeItem(i, obj._id)">mdi-close-circle</v-icon>
            </v-list-item-action>
        </v-list-item>
    </v-list>
    <!-- <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!model"
        color="grey darken-3"
        @click="clearSearch()"
      >
        Clear
        <v-icon right>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      recommendedSpots: []
    }),

    created() {
        this.$store.dispatch('activity/getHighlights').then((response)=> {
            this.recommendedSpots = response
        }, err => {
            console.log(err)
        })
    },

    methods: {
        addItem(item) {
            const payload = {_id:item._id, status: true}
            this.isLoading = true
            this.$store.dispatch('activity/updateHighlight', payload).then((response)=> {
                if (response.result.ok){
                    this.recommendedSpots.push(item)
                    console.log(this.recommendedSpots)
                    setTimeout(()=>{
                        this.clearSearch()
                        this.isLoading = false
                    }, 100)
                }
            }, err => {
                console.log(err)
                this.isLoading = false
            })
        },
        removeItem(idx, _id) {
            const payload = {_id:_id, status: false}
            this.isLoading = true
            this.$store.dispatch('activity/updateHighlight', payload).then((response)=> {
                if (response.result.ok){
                    this.recommendedSpots.splice(idx, 1)
                    setTimeout(()=>{
                        this.clearSearch()
                    }, 100)
                    this.isLoading = false
                }
            }, err => {
                console.log(err)
                this.isLoading = false
            })
            console.log(idx)
        },
        clearSearch() {
            this.model = null
        }
    },

    computed: {
      fields () {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
      items () {
        return this.entries.map(entry => {
          console.log(entry)
          const SearchText = entry.name.length > this.descriptionLimit
            ? entry.name.slice(0, this.descriptionLimit) + '...'
            : entry.name

          return Object.assign({}, entry, { SearchText })
        })
      },
    },

    watch: {
      search (val) {
        console.log(val)
        // Items have already been loaded
        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        fetch('https://yarkey.adventuraja.id/api/activities')
          .then(res => res.json())
          .then(res => {
            this.count = res.length
            this.entries = res
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
    },
  }
</script>